<template>
  <div id="page">
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>
    <v-row>
      <v-col cols="12">
        <DataTable
          :items="items"
          :headers="headers"
          :itemActions="{
            edit: true,
          }"
          :preloader="preloader"
          @checkFacebookToken="checkTokenManually"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import DataTable from "@/components/ui/DataTable.vue";
export default {
  components: {
    PageHeader,
    DataTable,
  },

  data: function () {
    return {
      preloader: false,
      pageTitle: "Facebook App-Verbindungen",
      pageDescription:
        "Hier werden alle Facebook App-Verbindungen aufgelistet.<br/>Wenn der Token inaktiv ist, muss <strong>der Kunde über mitfitAdmin</strong> die App erneut autorisieren.",
      items: [],
      headers: [],
    };
  },

  mounted: function () {
    this.getData();
  },

  methods: {
    async checkTokenManually(item) {
      this.preloader = true;
      let response = await this.getRequest(
        `facebook/checkPageToken/${item.pageAccessTokenLong}`
      );
      this.preloader = false;
      if (response.status === 200) {
        const isValid = response.data.data.is_valid;

        if (isValid) {
          alert("Token ist gültig");
        } else {
          alert("Token ist ungültig");
        }
        this.getData();
      }
    },

    async getData() {
      this.preloader = true;
      let response = await this.getRequest("facebook/allAccounts");
      this.preloader = false;
      if (response.status === 200) {
        const result = response.data.data;

        // finde isTokenValid in this.items und setze es auf ja oder nein
        result.forEach((item) => {
          item.isTokenValid = item.isTokenValid === "1" ? true : false;
        });

        this.items = result;

        this.headers = [
          { text: "Verbindung", value: "isTokenValid", width: "100px" },
          { text: "Facebook-Page (Kunde)", value: "name" },
          { text: "tasks", value: "tasks" },
          { text: "Letzter Check", value: "tokenChecked" },
          { text: "Check", value: "checkFacebookToken" },
        ];
        this.preloader = false;
      }
    },
  },
  mixins: [apiRequest],
};
</script>
